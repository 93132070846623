<template>
  <div>
    <!-- loder -->
    <div class="loader-wrapper">
      <span class="loader"></span>
      <div class="loder-section left-section"></div>
      <div class="loder-section right-section"></div>
    </div>
    <!--==================================================-->
    <!-- Start Toptech Header Area Style One-->
    <!--==================================================-->
    <div class="header-area style-two" id="sticky-header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-3">
            <div class="header-logo">
              <router-link to="/">
                <a href=""
                  ><img src="/assets/images/home-2/logo.png" alt="logo"
                /></a>
              </router-link>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="header-menu">
              <ul class="w-100 d-flex align-items-center">
                <li class="menu-item-has-children ms-auto">
                  <a href="#">Platform<i class="fas fa-chevron-down"></i></a>
                  <ul class="sub-menu">
                    <li>
                      <a @click="menuClick('ConversationalIntelligence')"
                        >Conversational Intelligence</a
                      >
                    </li>
                    <li>
                      <a @click="menuClick('AssistantDesk')"
                        >AI Assistant Desk</a
                      >
                    </li>
                    <li><a @click="menuClick('VoiceAi')">Voice AI</a></li>
                    <li>
                      <a @click="menuClick('IntegrationPage')">Integration</a>
                    </li>
                  </ul>
                </li>
                <!-- <li class="menu-item-has-children">
                  <a href="#">Industry<i class="fas fa-chevron-down"></i></a>
                  <ul class="sub-menu">
                    <li><a href="#">E-commerce</a></li>
                    <li><a href="#">Banking and FS</a></li>
                    <li><a href="#">Travel</a></li>
                    <li><a href="#">Real Estate</a></li>
                    <li><a href="#">Logistics</a></li>
                    <li><a href="#">Insurance</a></li>
                    <li><a href="#">Foodtech</a></li>
                    <li><a href="#">EdTech</a></li>
                    <li><a href="#">Telecom</a></li>
                  </ul>
                </li> -->
                <li class="menu-item-has-children">
                  <a href="#">Solutions<i class="fas fa-chevron-down"></i></a>
                  <div class="sub-menu sub-menu-long">
                    <ul>
                      <h3>By Use Cases</h3>
                      <li>
                        <a @click="menuClick('CustomerSolution')">
                          <span class="bi bi-headset"></span>
                          <div class="new-sub-menu">
                            <h5>Customer Service</h5>
                            <p>Automate & personalize <br />customer support</p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a @click="menuClick('EmployeeExperience')">
                          <span class="bi bi-briefcase"></span>
                          <div class="new-sub-menu">
                            <h5>Employee Experience</h5>
                            <p>Enrich & elevate <br />employee experience</p>
                          </div>
                        </a>
                      </li>
                    </ul>
                    <ul>
                      <h3>By Industry</h3>
                      <li>
                        <a @click="menuClick('ConversationalAIHealthcare')">
                          <span class="bi bi-hospital"></span>
                          <div class="new-sub-menu">
                            <h5>Healthcare</h5>
                            <p>
                              Solutions for healthcare and pharmaceuticals
                              industry
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a @click="menuClick('ConversationalAIHealthcare')" class="menu-image-header">
                          <img
                            src="/assets/images/service-inner/services-details-benifis-thumb-1.png"
                            alt=""
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="menu-item-has-children">
                  <a href="#">Company<i class="fas fa-chevron-down"></i></a>
                  <ul class="sub-menu">
                    <li><a @click="menuClick('AboutUs')">About Us</a></li>
                    <li><a @click="menuClick('ContactUs')">Contact Us</a></li>
                    <!-- <li><a href="#">PR/News</a></li>
                    <li><a href="#">Customers</a></li>
                    <li><a href="#">Careers</a></li> -->
                  </ul>
                </li>
                <!-- <li><a href="#">Partners</a></li> -->
                <!-- <li><a href="#">Resources</a></li> -->
                <li class="ms-auto"><a href="#">Sign In</a></li>
                <div class="header-button">
                  <router-link :to="'/request'" title="Request"
                    >Schedule a Demo</router-link>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End Toptech Header Area Style One-->
    <!--==================================================-->
  </div>
</template>

<script>
export default {
  name: "HeaderBar",
  methods: {
    menuClick(url) {
      this.$router.push({ name: url });
      this.nameUrl = url;
    },
  },
};
</script>

<style></style>
