<template>
  <!--==================================================-->
  <!-- Start Main Menu Area -->
  <!--==================================================-->
  <div class="mobile-menu-area sticky d-sm-block d-md-block d-lg-none">
    <div class="mobile-menu">
      <nav class="header-menu">
        <ul class="nav_scroll">
          <li class="menu-item-has-children">
            <a href="#">Home</a>
            <ul class="sub-menu">
              <li>
                <a href="https://html.ditsolution.net/toptech/">Home 1</a>
              </li>
              <li>
                <a href="https://html.ditsolution.net/toptech/index-2.html"
                  >Home 2</a
                >
              </li>
              <li>
                <a href="https://html.ditsolution.net/toptech/index-3.html"
                  >Home 3</a
                >
              </li>
              <li>
                <a href="https://html.ditsolution.net/toptech/index-4.html"
                  >Home 4</a
                >
              </li>
              <li>
                <a href="https://html.ditsolution.net/toptech2/"
                  >Home 5 (New)</a
                >
              </li>
              <li>
                <a href="https://html.ditsolution.net/toptech2/index-2.html"
                  >Home 6 (New)</a
                >
              </li>
            </ul>
          </li>
          <li class="menu-item-has-children">
            <a href="#">About</a>
            <ul class="sub-menu">
              <li><a href="about-1.html">About Us 1</a></li>
              <li><a href="about-2.html">About Us 2</a></li>
            </ul>
          </li>
          <li class="menu-item-has-children">
            <a href="#">Service</a>
            <ul class="sub-menu">
              <li><a href="service.html">Service</a></li>
              <li><a href="service-details.html">Service Details</a></li>
            </ul>
          </li>
          <li class="menu-item-has-children">
            <a href="#">Pages</a>
            <ul class="sub-menu">
              <li><a href="choose-us.html">Why Choose Us</a></li>
              <li><a href="mission.html">Mission Visson </a></li>
              <li><a href="team.html">Our Team</a></li>
              <li><a href="team-details.html">Team Details </a></li>
              <li><a href="project.html">Our Project</a></li>
              <li><a href="project-details.html">Project Details </a></li>
              <li><a href="location.html">Our Location </a></li>
              <li><a href="pricing.html">Our Pricing</a></li>
              <li><a href="careers.html">Careers </a></li>
              <li><a href="faqs.html">Faqs</a></li>
            </ul>
          </li>
          <li class="menu-item-has-children">
            <a href="#">Blog</a>
            <ul class="sub-menu">
              <li><a href="blog-grid.html">Blog Grid</a></li>
              <li><a href="blog-list.html">Blog List</a></li>
              <li><a href="blog-2column.html">Blog 2column</a></li>
              <li><a href="blog-details.html">Blog Details </a></li>
            </ul>
          </li>
          <li><a href="contact.html">Contact</a></li>
        </ul>
      </nav>
    </div>
  </div>
  <!--==================================================-->
  <!-- End Main Menu Area -->
  <!--==================================================-->

  <!--==================================================-->
  <!-- Start Search Popup -->
  <!--==================================================-->
  <div class="search-popup">
    <button class="close-search style-two"><i class="fas fa-times"></i></button>
    <button class="close-search"><i class="fas fa-arrow-up"></i></button>
    <form method="post" action="#">
      <div class="form-group">
        <input
          id="search1"
          type="search"
          name="search-field"
          value=""
          placeholder="Search Here"
          required=""
        />
        <button type="submit"><i class="fas fa-search"></i></button>
      </div>
    </form>
  </div>
  <!--==================================================-->
  <!-- End Search Popup -->
  <!--==================================================-->

  <!--==================================================-->
  <!-- Start Toptech Hero Area Style three-->
  <!--==================================================-->
  <div class="hero-area style-three d-flex align-items-center">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-lg-6">
          <div class="hero-content">
            <h1>
              Transforming Customer Interactions with Intelligent Assistants
            </h1>
            <!-- <h1>Digital Marketing</h1>
                        <h1>Agency</h1> -->
            <!-- <img src="/assets/images/home-2/hero-profile.png" alt="img" /> -->
            <p>
              Our solutions streamline your business processes and ensure that
              every customer interaction is smooth and productive.
            </p>
          </div>
          <div class="hero-button">
            <router-link :to="'/customer-service'" title="Request"
            >READ MORE</router-link>
            <!-- <a >READ MORE<i class="bi bi-arrow-right"></i
            ></a> -->
          </div>
          <!-- <div class="hero-play-btn">
            <a
              class="video-vemo-icon venobox vbox-item"
              data-vbtype="youtube"
              data-autoplay="true"
              href="https://www.youtube.com/watch?v=Wx48y_fOfiY"
              >Play</a
            >
            <span>HOW IT WORKS</span>
          </div> -->
        </div>
        <div class="col-lg-6">
          <div class="hero-right">
            <div class="hero-thumb">
              <img src="/assets/images/home-2/hero-thumb2.png" alt="thumb" />
            </div>
            <div class="hero-thumb-shape5 bounce-animate-3">
              <img src="/assets/images/home-2/hero-2-shape.png" alt="shape" />
            </div>
            <div class="hero-thumb-shape6">
              <img src="/assets/images/home-2/hero-2-shape3.png" alt="shape" />
            </div>
            <div class="hero-thumb-shape7">
              <img src="/assets/images/home-2/hero-2-shape4.png" alt="shape" />
              <div class="hero-logo">
                <div class="text-inner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="250.5"
                    height="250.5"
                    viewBox="0 0 250.5 250.5"
                  >
                    <path
                      d="M.25,125.25a125,125,0,1,1,125,125,125,125,0,0,1-125-125"
                      id="e-path-35ee1b2"
                    ></path>
                    <text>
                      <textPath
                        id="e-text-path-35ee1b2"
                        href="#e-path-35ee1b2"
                        startOffset="0%"
                      >
                        Digital Marketing agency Digital marketing
                      </textPath>
                    </text>
                  </svg>
                </div>
              </div>
            </div>
            <div class="hero-thumb-shape8 bounce-animate-3">
              <img src="/assets/images/home-2/hero-2-shape2.png" alt="shape" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--==================================================-->
  <!-- End Toptech Hero Area Style three-->
  <!--==================================================-->

  <!--==================================================-->
  <!-- Start toptech marquee-section  -->
  <!--==================================================-->
  <section class="marquee-section">
    <div class="inner-container">
      <div class="marquee">
        <div class="marquee-block">
          <!-- content-box -->
          <div class="content-box">
            <h6 class="title">
              <a href="#"
                ><img
                  src="/assets/images/home-2/marquee-star.png"
                  alt="star"
                />Conversational Intelligence</a
              >
            </h6>
          </div>
          <!-- content-box -->
          <div class="content-box">
            <h6 class="title">
              <a href="#"
                ><img
                  src="/assets/images/home-2/marquee-star.png"
                  alt="star"
                />AI Assistant Desk</a
              >
            </h6>
          </div>
          <!-- content-box -->
          <div class="content-box">
            <h6 class="title">
              <a href="#"
                ><img
                  src="/assets/images/home-2/marquee-star.png"
                  alt="star"
                />Voice AI
              </a>
            </h6>
          </div>
          <!-- content-box -->
          <div class="content-box">
            <h6 class="title">
              <a href="#"
                ><img
                  src="/assets/images/home-2/marquee-star.png"
                  alt="star"
                />Integration</a
              >
            </h6>
          </div>
        </div>
        <div class="marquee-block">
          <!-- content-box -->
          <div class="content-box">
            <h6 class="title">
              <a href="#"
                ><img
                  src="/assets/images/home-2/marquee-star.png"
                  alt="star"
                />Conversational Intelligence</a
              >
            </h6>
          </div>
          <!-- content-box -->
          <div class="content-box">
            <h6 class="title">
              <a href="#"
                ><img
                  src="/assets/images/home-2/marquee-star.png"
                  alt="star"
                />AI Assistant Desk</a
              >
            </h6>
          </div>
          <!-- content-box -->
          <div class="content-box">
            <h6 class="title">
              <a href="#"
                ><img
                  src="/assets/images/home-2/marquee-star.png"
                  alt="star"
                />Voice AI
              </a>
            </h6>
          </div>
          <!-- content-box -->
          <div class="content-box">
            <h6 class="title">
              <a href="#"
                ><img
                  src="/assets/images/home-2/marquee-star.png"
                  alt="star"
                />Integration</a
              >
            </h6>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--==================================================-->
  <!-- End toptech marquee-section  -->
  <!--==================================================-->

  <!--==================================================-->
  <!-- Start Toptech Feature Area home two Style four-->
  <!--==================================================-->
  <div class="feature-area style-four">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title left style-three">
            <h4>WHAT AI ASSISTANT DO</h4>
            <h1>Smarter Conversations for Enterprises of Any Size</h1>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="section-title left style-three">
            <p>
              No matter the size of your business, our AI assistant can adapt to
              your needs. From small start-ups to large enterprises, we provide
              smart conversational solutions that help you improve efficiency,
              customer satisfaction, and profitability.
            </p>
            <div class="section-btn">
              <!-- <a href="#">READ MORE<i class="bi bi-arrow-right"></i></a> -->
              <router-link :to="'/conversational-AI'" title="Request"
              >READ MORE</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6">
          <div class="single-feature-box">
            <div class="single-feature-inner justify-content-between">
              <h4 class="feature-title">
                Sales, Support, and <br />
                Beyond
              </h4>
              <div class="feature-icon">
                <img src="/assets/images/home-2/feature-icon5.png" alt="icon" />
              </div>
            </div>
            <div class="feature-desc">
              <p>
                Go beyond basic support, offering comprehensive solutions for
                sales, customer service, and business processes. Whether you
                need assistance with follow-ups or closing deals, our assistant
                are designed to exceed your expectations.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6">
          <div class="single-feature-box">
            <div class="single-feature-inner">
              <h4 class="feature-title">Revolutionize Customer Engagement</h4>
              <div class="feature-icon">
                <img src="/assets/images/home-2/feature-icon6.png" alt="icon" />
              </div>
            </div>
            <div class="feature-desc">
              <p>
                Say goodbye to traditional customer service and embrace the
                future with our intelligent AI Assistant Desk. Revolutionize how
                your business interacts with clients by providing seamless,
                personalized assistance that keeps customers coming back.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6">
          <div class="single-feature-box">
            <div class="single-feature-inner">
              <h4 class="feature-title">Transforming Customer Interactions</h4>
              <div class="feature-icon">
                <img src="/assets/images/home-2/feature-icon7.png" alt="icon" />
              </div>
            </div>
            <div class="feature-desc">
              <p>
                Redefine the customer experience with smart assistant that
                understand and respond to your clients’ needs in real-time. Our
                solutions streamline your business processes and ensure that
                every customer interaction is smooth and productive.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--==================================================-->
  <!-- End Toptech Feature Area home two Style four-->
  <!--==================================================-->

  <!--==================================================-->
  <!-- Start Toptech award agency Area home two Style two-->
  <!--==================================================-->
  <div class="award-agency-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="award-thumbail-wrapper overlay-bg">
            <div class="award-thumb">
              <img src="/assets/images/home-2/award-thumb.png" alt="thumb" />
            </div>
            <div class="award-box">
              <div class="award-box-inner">
                <div class="award-title">
                  <h5>
                    wining
                    <span>Award</span>
                    <span>Agency</span>
                  </h5>
                </div>
                <div class="award-img">
                  <img src="/assets/images/home-2/award-img.png" alt="img" />
                </div>
              </div>
            </div>
            <div class="award-shape">
              <img src="/assets/images/home-2/award-shape.png" alt="shape" />
            </div>
            <div class="award-shape2">
              <img src="/assets/images/home-2/award-shape2.png" alt="shape" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--==================================================-->
  <!-- End Toptech award agency Area home two Style two-->
  <!--==================================================-->

  <!--==================================================-->
  <!-- Start Toptech service area home two Style Two-->
  <!--==================================================-->
  <div class="service-area style-two">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title left style-three">
            <h4>Our Solutions</h4>
            <h1>Provide Solutions that Speak Your Business Language</h1>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="section-title left style-three">
            <p>
              Your business is unique, and so are our AI solutions. We build AI
              assistants that are fluent in your industry and processes,
              offering seamless communication that aligns perfectly with your
              company’s goals and objectives.
            </p>
            <div class="section-btn">
              <router-link :to="'/assistant-desk'" title="Request"
            >READ MORE</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-6">
          <div class="single-service-box style-three p-4">
            <div class="serive-icon">
              <img src="/assets/images/home-2/ser-icon1.png" alt="icon" />
            </div>
            <div class="service-content">
              <h3 class="service-title">Conversation</h3>
              <p class="service-desc">
                Unlock the power of AI-driven conversations to boost efficiency,
                enhance customer engagement, and drive meaningful business
                growth."
              </p>
            </div>
            <div class="section-btn style-three">
              <router-link :to="'/assistant-desk'" title="Request"
            >READ MORE</router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6">
          <div class="single-service-box style-three p-4">
            <div class="serive-icon">
              <img src="/assets/images/home-2/ser-icon2.png" alt="icon" />
            </div>
            <div class="service-content">
              <h3 class="service-title">Sales & Marketing</h3>
              <p class="service-desc">
                Supercharge your sales and marketing efforts with AI assistants
                that personalize customer interactions, streamline lead
                management, and drive higher conversions.
              </p>
            </div>
            <div class="section-btn style-three">
              <router-link :to="'/assistant-desk'" title="Request"
            >READ MORE</router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6">
          <div class="single-service-box style-three p-4">
            <div class="serive-icon">
              <img src="/assets/images/home-2/ser-icon3.png" alt="icon" />
            </div>
            <div class="service-content">
              <h3 class="service-title">Business Process Automation</h3>
              <p class="service-desc">
                Enhance efficiency and productivity by automating repetitive
                tasks with AI assistants, allowing your team to focus on
                strategic business growth.
              </p>
            </div>
            <div class="section-btn style-three">
              <router-link :to="'/assistant-desk'" title="Request"
              >READ MORE</router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6">
          <div class="single-service-box style-three p-4">
            <div class="serive-icon">
              <img src="/assets/images/home-2/ser-icon4.png" alt="icon" />
            </div>
            <div class="service-content">
              <h3 class="service-title">Follow-ups</h3>
              <p class="service-desc">
                AI-powered follow-ups ensure timely, personalized communication,
                helping businesses nurture leads, retain customers, and close
                deals more effectively.
              </p>
            </div>
            <div class="section-btn style-three">
              <router-link :to="'/assistant-desk'" title="Request"
              >READ MORE</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--==================================================-->
  <!-- End Toptech service area home two Style Two-->
  <!--==================================================-->

  <!--==================================================-->
  <!-- Start Toptech abour Area home two Style style three-->
  <!--==================================================-->
  <div class="about-area style-four">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="about-thumbail-wrapper">
            <div class="about-thumb">
              <img src="/assets/images/home-2/about-thum11.png" alt="thumb" />
            </div>
            <div class="about-thumb2 bounce-animate-3">
              <img src="/assets/images/home-2/about-thumb12.png" alt="thumb" />
            </div>
            <div class="about-shape bounce-animate-3">
              <img src="/assets/images/home-2/about-shape6.png" alt="shape" />
            </div>
            <div class="about-shape2">
              <img src="/assets/images/home-2/about-shape7.png" alt="shape" />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-content">
            <div class="section-title left style-three">
              <h4>Healthcare</h4>
              <h1>Why healthcare clinics need AI assisted customer services</h1>
            </div>
            <div class="section-title left style-three">
              <p>
                Healthcare clinics need AI-assisted customer services to
                streamline patient interactions, reduce wait times, and provide
                24/7 support for appointment scheduling, inquiries, and
                follow-ups. AI enhances operational efficiency, allowing clinics
                to focus more on patient care while maintaining excellent
                service.
              </p>
              <div class="section-btn">
                <router-link :to="'/conversational-AI-healthcare'" title="Request"
            >READ MORE</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--==================================================-->
  <!-- End Toptech abour Area home two Style style three-->
  <!--==================================================-->

  <!--==================================================-->
  <!-- Start Toptech stories area home two-->
  <!--==================================================-->
  <!-- <div class="stories-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4">
          <div class="section-title left style-three">
            <h4>SUCCESS STORIES</h4>
            <h1>Our work drives</h1>
            <h1>businesses forward</h1>
          </div>
          <div class="section-title left style-three">
            <p>
              We are a five-star rated holistic full-service digital serving
              thousands of clients. Our digital
            </p>
            <div class="section-btn">
              <a href="#">DISCOVER MORE<i class="bi bi-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="single-stories-box">
            <div class="stories-content">
              <h5 class="stories-title"><a href="#">TATA</a></h5>
              <h4 class="stories-sub-title">+25%</h4>
              <p class="stories-desc">Ecommerce growth</p>
            </div>
            <div class="stories-menu-item">
              <ul>
                <li><a class="active" href="#">Paid Search</a></li>
                <li><a class="margin" href="#">Paid Socia</a></li>
                <li><a href="#">SEO</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="single-stories-box2">
            <div class="stories-content">
              <h5 class="stories-title"><a href="#">Nike</a></h5>
              <h4 class="stories-sub-title">+30%</h4>
              <p class="stories-desc">Ecommerce growth</p>
            </div>
            <div class="stories-menu-item">
              <ul>
                <li><a class="active" href="#">Paid Search</a></li>
                <li><a class="margin" href="#">Paid Socia</a></li>
                <li><a href="#">SEO</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!--==================================================-->
  <!-- Start Toptech stories area home two-->
  <!--==================================================-->

  <!--==================================================-->
  <!-- start Toptech-brand Area home two style-two-->
  <!--==================================================-->
  <!-- <div class="brand-area style-two section-border">
    <div class="container">
      <div class="row border-btm">
        <div class="band-title">
          <h3>The best brands choose Numerique</h3>
        </div>
        <div class="brand_list owl-carousel">
          <div class="col-lg-12">
            <div class="brand-box">
              <div class="brand-thumb">
                <img
                  src="/assets/images/home-2/brand-img6.png"
                  alt="brand img"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="brand-box">
              <div class="brand-thumb">
                <img
                  src="/assets/images/home-2/brand-img7.png"
                  alt="brand img"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="brand-box">
              <div class="brand-thumb">
                <img
                  src="/assets/images/home-2/brand-img8.png"
                  alt="brand img"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="brand-box">
              <div class="brand-thumb">
                <img
                  src="/assets/images/home-2/brand-img9.png"
                  alt="brand img"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="brand-box">
              <div class="brand-thumb">
                <img
                  src="/assets/images/home-2/brand-img10.png"
                  alt="brand img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!--==================================================-->
  <!-- end Toptech-brand Area home two style-two -->
  <!--==================================================-->

  <!--==================================================-->
  <!-- start Toptech counter area home two style-two-->
  <!--==================================================-->
  <!-- <div class="counter-area style-two">
    <div class="container">
      <div class="row counter-bg">
        <div class="col-lg-12 text-center">
          <div class="section-title center style-three">
            <h1>The proof is in the numbers</h1>
            <p>
              Nam sapien feugiat id ipsum quam massa. Convallis est morbi semper
              posuere arcu diam facilisi aliquam sit. Sit tincidunt turpis
              consequat lacus blandit lorem.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="counter-single-box counter-center">
              <div class="counter-content">
                <h3 class="counter">10</h3>
                <span>+</span>
                <i class="fas fa-arrow-right"></i>
              </div>
              <div class="counter-desc">
                <p>Years of experience</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="counter-single-box">
              <div class="counter-content">
                <h3 class="counter">500</h3>
                <span>+</span>
                <i class="fas fa-arrow-right"></i>
              </div>
              <div class="counter-desc">
                <p>Satisfied clients</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="counter-single-box counter-center">
              <div class="counter-content">
                <h3 class="counter">1000</h3>
                <span>+</span>
                <i class="fas fa-arrow-right"></i>
              </div>
              <div class="counter-desc">
                <p>Successful campaigns</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="counter-single-box2">
              <div class="counter-content2 style-three">
                <div class="counter-titel">
                  <h5>Leads generated so far…</h5>
                </div>
                <h3 class="counter">211,000</h3>
                <span>+</span>
                <div class="section-btn style-three">
                  <a href="#">view all<i class="bi bi-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!--==================================================-->
  <!--End Toptech counter area home two style-two-->
  <!--==================================================-->

  <!--==================================================-->
  <!-- start Toptech testimonial area home two style-two-->
  <!--==================================================-->
  <!-- <div class="testimonial-area style-two section-border">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="reviews-left-content">
            <div class="section-title left style-three">
              <h4>Client reviews</h4>
              <h1>Driving digital revenue for</h1>
              <h1>our 1000+ satisfied</h1>
              <h1>customers</h1>
            </div>
            <div class="single-reviews-box">
              <div class="reviews-content">
                <div class="reviews-icon">
                  <img src="/assets/images/home-2/reviews-icon.png" alt="icon" />
                </div>
                <div class="reviews-title">
                  <h2>21%</h2>
                </div>
                <div class="reviws-desc">
                  <p>Average Traffic <span>Increase for Clients</span></p>
                </div>
              </div>
            </div>
            <div class="reviews-shape">
              <img src="/assets/images/home-2/reviews-shape.png" alt="shape" />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="col-lg-12 col-md-10">
            <div class="testi-list-2 owl-carousel">
              <div class="testi-single-box">
                <p class="testi-dasc">
                  “Working with Numerique Digital Marketing team provided us
                  with the expert guidance we needed to take our firm’s
                  marketing, in the digital realm and beyond, to another level.
                  Early in the project, we threw a curveball"
                </p>
                <div class="testi-author-box">
                  <div class="testi-autor-content">
                    <h3 class="testi-title">
                      <a href="#">Charith Asalanka</a>
                    </h3>
                    <span class="testi-designation"
                      >Director, Client Experience</span
                    >
                  </div>
                </div>
                <div class="testi-reviews-box">
                  <div class="testi-autor">
                    <div class="testi-reviews-autor">
                      <img
                        src="/assets/images/home-2/testi-autor.png"
                        alt="img"
                      />
                    </div>
                    <div class="testi-reviews-content">
                      <h4>4.5</h4>
                      <ul class="testi-ratting">
                        <li><i class="bi bi-star-fill"></i></li>
                        <li><i class="bi bi-star-fill"></i></li>
                        <li><i class="bi bi-star-fill"></i></li>
                        <li><i class="bi bi-star-fill"></i></li>
                        <li><i class="bi bi-star-fill"></i></li>
                      </ul>
                      <span class="reviews-count">5000+ Client reviews</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!--==================================================-->
  <!-- start Toptech testimonial area home two style-two-->
  <!--==================================================-->

  <!--==================================================-->
  <!-- start Toptech blog area home two style-two-->
  <!--==================================================-->
  <!-- <div class="blog-area style-two">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="section-title center style-three">
            <h4>Blog</h4>
            <h1>Think further with our</h1>
            <h1>expert insights</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="single-blog-box">
            <div class="blog-thumb">
              <img src="/assets/images/home-2/blog-thumb21.png" alt="thumb" />
            </div>
            <div class="blog-content">
              <h1 class="blog-title">
                <a href="blog-details.html"
                  >6 Visual Design Principles that UX Designers</a
                >
              </h1>
              <p class="blog-meta-date">APRIL 12, 2024</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-blog-box">
            <div class="blog-thumb">
              <img src="/assets/images/home-2/blog-thumb22.png" alt="thumb" />
            </div>
            <div class="blog-content">
              <h1 class="blog-title">
                <a href="blog-details.html"
                  >10 Questions I wish I’d Asked More to Turbocharge My
                  Career</a
                >
              </h1>
              <p class="blog-meta-date">MARCH 23, 2024</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-blog-box">
            <div class="blog-thumb">
              <img src="/assets/images/home-2/blog-thumb23.png" alt="" />
            </div>
            <div class="blog-content">
              <h1 class="blog-title">
                <a href="blog-details.html"
                  >Turbocharge My Career Questions I wish I’d Asked More
                </a>
              </h1>
              <p class="blog-meta-date">JUNE 12, 2024</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!--==================================================-->
  <!-- start Toptech blog area home two style-two-->
  <!--==================================================-->
</template>
<script>
export default {
  name: "HomeView",
};
</script>

<style></style>
