<template>
  <div>
    <HeaderBar />
    <router-view />
    <FooterBar />
    <CookieBanner/>
  </div>
</template>
<script>
import CookieBanner from './components/CookieBanner.vue';
import HeaderBar from "./views/HeaderBar.vue";
import FooterBar from "./views/FooterBar.vue";
import $ from "jquery";
// import "../public//assets/js/scroll-up.js";
// Import Bootstrap CSS and JS

export default {
  mounted() {
    (function ($) {
      "use strict";
      // scroll up
      if ($(".prgoress_indicator path").length) {
        var progressPath = document.querySelector(".prgoress_indicator path");
        var pathLength = progressPath.getTotalLength();
        progressPath.style.transition = progressPath.style.WebkitTransition =
          "none";
        progressPath.style.strokeDasharray = pathLength + " " + pathLength;
        progressPath.style.strokeDashoffset = pathLength;
        progressPath.getBoundingClientRect();
        progressPath.style.transition = progressPath.style.WebkitTransition =
          "stroke-dashoffset 10ms linear";
        var updateProgress = function () {
          var scroll = $(window).scrollTop();
          var height = $(document).height() - $(window).height();
          var progress = pathLength - (scroll * pathLength) / height;
          progressPath.style.strokeDashoffset = progress;
        };
        updateProgress();
        $(window).on("scroll", updateProgress);
        var offset = 250;
        var duration = 550;
        $(window).on("scroll", function () {
          if ($(this).scrollTop() > offset) {
            $(".prgoress_indicator").addClass("active-progress");
          } else {
            $(".prgoress_indicator").removeClass("active-progress");
          }
        });
        $(".prgoress_indicator").on("click", function (event) {
          event.preventDefault();
          $("html, body").animate({ scrollTop: 0 }, duration);
          return false;
        });
      }
    })($);
  },
  name: "App",
  components: {
    HeaderBar,
    FooterBar,
    CookieBanner
  },
};
</script>
<style></style>
