<template>
  <div>
    <!--==================================================-->
    <!-- Start Toptech Footer Area Style two-->
    <!--==================================================-->
    <div class="footer-area style-two">
      <div class="container">
        <div class="row footer-top section-border align-items-center">
          <div class="col-lg-5 col-md-12">
            <div class="footer-title style-three">
              <h2>
                Are You Ready for Your<br />
                Business Success?
              </h2>
              <div class="footer-widget-desc">
                <p>
                  Make your businesses scale operations, personalize services,
                  and make the data-driven decisions faster.
                </p>
              </div>
              <div class="section-btn style-three">
                <!-- <a href="#">READ MORE<i class="bi bi-arrow-right"></i></a> -->
                <router-link :to="'/conversational-AI'" title="Request"
                  >READ MORE</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="footer-shape">
              <img src="/assets/images/home-2/footer-shape.png" alt="shape" />
            </div>
          </div>
          <div class="col-lg-3 col-md-12">
            <div class="footer-info-box">
              <div class="footer-info-content">
                <h4 class="footer-info-sub-title">
                  <i class="fab fa-envelope-o"></i>
                  <p class="footer-info-desc">Connect us at</p>
                  <!-- <a href="#">Connect us at</a> -->
                </h4>
                <h1 class="footer-info-title">info@assistantdesk.io</h1>
                <p class="footer-info-desc">
                  Send an email to our marketing expert. We will assist you.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row footer-main">
          <div class="col-xl-3 col-lg-3 col-md-6">
            <div class="footer-logo">
              <img src="/assets/images/home-2/logo.png" alt="logo" width="70%" />
            </div>
            <div class="footer-wiget-menu">
              <ul class="wiget-menu">
                <li class="mb-3">
                  <h6>India office:</h6>
                  <a
                    >DF-52, Scheme no. 74, Vijaynagar,<br />
                    Indore, (M.P.) (452010)</a
                  >
                </li>
                <li>
                  <h6>Singapore Office:</h6>
                  <a href="#"
                    >65 Tampines Avenue 1, The Tropica, <br />Singapore (529778)
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-6">
            <div class="footer-wiget-title">
              <h3>Platform</h3>
            </div>
            <div class="footer-wiget-menu">
              <ul>
                <li>
                  <a href="#">Conversational Intelligence</a>
                </li>
                <li><a href="#">AI Assistant Desk</a></li>
                <li><a href="#">Voice AI</a></li>
              </ul>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-6">
            <div class="footer-wiget-title">
              <h3>Solution</h3>
            </div>
            <div class="footer-wiget-menu">
              <ul>
                <li>
                  <a href="about-1.html">Customer Service</a>
                </li>
                <li><a href="blog-grid.html">Employee Experience</a></li>
                <li><a href="careers.html">Healthcare</a></li>
                <!-- <li><a href="team.html">Other Industries</a></li> -->
              </ul>
            </div>
          </div>

          <div class="col-xl-2 col-lg-2 col-md-6">
            <div class="footer-wiget-title">
              <h3>Integration</h3>
            </div>
            <div class="footer-wiget-menu">
              <ul>
                <li><a href="about-1.html">Hubspot</a></li>
                <li><a href="blog-grid.html">Freshdesk</a></li>
                <li>
                  <a href="careers.html"
                    >vTiger
                    <!-- <span style="font-size: 14px; font-style: italic"
                    >and many more...</span
                  > -->
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6">
            <div class="footer-wiget-title">
              <h3>CONTACT US</h3>
            </div>
            <form @submit.prevent="submitForm">
              <div class="footer-newslatter-box">
                <p v-if="successMessage" class="text text-success" :class="{ 'text-active': successMessage != null }">
              {{ successMessage }}
            </p>
                <input type="text" placeholder="Enter Your Email..."  v-model="formFields.email_address" />
                <button type="submit">Send</button>
                <span v-if="v$.email_address.$error" class="text text-danger"> Email address is Required.</span>
              </div>
            </form>
            <p class="footer-desc2">
              We are 24X7 available to respond your queries.
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="footer-bottom-area">
            <div class="row footer-bottom">
              <div class="col-lg-12 col-md-8">
                <div class="footer-bottom-content">
                  <p>
                    © 2024 IRA Softwares. All rights reserved.
                    <a
                      @click="menuClick('TermsofServices')"
                      class="me-3 ms-auto"
                      >Terms of Services</a
                    >
                    <a @click="menuClick('PrivacyPolicy')"> Privacy Policy</a>
                  </p>
                </div>
              </div>
              <!-- <div class="col-lg-6 col-md-4">
              <div class="footer-botton-social">
                <ul>
                  <li>
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-dribbble"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                  </li>
                </ul>
              </div>
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End Toptech Footer Area Style two-->
    <!--==================================================-->

    <!--==================================================-->
    <!-- Start Toptech Scroll Up-->
    <!--==================================================-->
    <!-- <div class="prgoress_indicator active-progress">
      <svg
        class="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path
          d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          style="
            transition: stroke-dashoffset 10ms linear 0s;
            stroke-dasharray: 307.919, 307.919;
            stroke-dashoffset: 212.78;
          "
        ></path>
      </svg>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
export default {
  name: "CustomerSolution",
  data() {
    return {
      errorMessage: null,
      successMessage: null,
    };
  },
  setup() {
    const formFields = reactive({
      email_address: "",
      request: 5,
    });
    const rules = {
      email_address: {
        required,
        email,
      },
    };
    const v$ = useVuelidate(rules, formFields);
    return {
      formFields,
      v$,
    };
  },
  methods: {
    menuClick(url) {
      this.$router.push({ name: url });
      this.nameUrl = url;
    },
    async pushNotification(){
      const text_contect={"content" : "Get requested by email"};
      const url = window.location.hostname == "localhost"
          ? " https://192.168.0.87:3003/api/user/createAdminNotification"
          : "https://hexadegree.com:3003/api/user/createAdminNotification";
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify(text_contect),
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },

    async submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("XXXXXXXXXXXXX", this.formFields);
        const url =
          window.location.hostname == "localhost"
            ? "https://192.168.0.87:3003/api/user/createContactUsByMail"
            : "https://hexadegree.com:3003/api/user/createContactUsByMail";
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formFields),
          });
          if (response.ok) {
            this.pushNotification();
            const data = await response.json();
            this.successMessage = "request has been sent.";
            setTimeout(
              function () {
                this.successMessage = null;
              }.bind(this),
              3000
            );
            this.formFields.email_address = "";
          //  this.formFields={
          //   email_address: ""
          //  }
          } else {
            console.error("Error:", response.statusText);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },
  },
};
</script>
