<template>
  <div class="layout-error">
    <div class="error-container">
      <div class="error-code">404</div>
      <div class="error-message">
        Oops! The page you're looking for can't be found.
      </div>
      <router-link :to="'/'">Go Back to Home</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
<style scoped>
.layout-error {
  height: 100%;
  margin: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}
.error-container {
  text-align: center;
}
.error-code {
  font-size: 10rem;
  font-weight: bold;
  color: #6c757d;
}
.error-message {
  font-size: 1.5rem;
  color: #495057;
  margin-bottom: 1.5rem;
}
.error-container a {
  text-decoration: none;
  color: #007bff;
  border: 2px solid #007bff;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  transition: background-color 0.3s ease;
}
.error-container a:hover {
  background-color: #007bff;
  color: white;
}
</style>
