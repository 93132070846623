<template>
  <div v-if="showBanner" class="cookie-banner">
    <h3>We value your privacy</h3>
    <p>
      This site uses cookies to enhance the user experience. By continuing to use the site, you agree to our use of cookies.
    </p>
    <div class="button-container">
      <button class="btn btn-primary" @click="acceptCookies">Accept</button>&nbsp;
      <button class="btn btn-secondary" @click="declineCookies">Decline</button>
    </div>

    <!-- Popup for more information -->
    <div v-if="showPopup" class="popup">
      <div class="popup-content">
        <span class="close" @click="closePopup">&times;</span>
        <h4>Cookie Policy</h4>
        <p>Details about our cookie policy...</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBanner: false,
      showPopup: false,
    };
  },
  mounted() {
    this.checkCookieConsent();
  },
  methods: {
    checkCookieConsent() {
      const consent = this.getCookie("cookieConsent");
      if (!consent) {
        this.showBanner = true;
      }
    },
    acceptCookies() {
      this.setCookie("cookieConsent", "accepted", 365);
      this.showBanner = false;
    },
    declineCookies() {
      this.setCookie("cookieConsent", "declined", 365);
      this.showBanner = false;
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    closePopup() {
      this.showPopup = false;
    },
    showPopupInfo() {
      this.showPopup = true;
    }
  },
};
</script>

<style scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  background-color: #f1f1f1;
  padding: 20px;
  width: 100%;
  border-top: 1px solid #ccc;
  z-index: 1000;
  text-align: center;
}
.button-container {
  margin-top: 10px; 
}
.popup {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: left;
  position: relative;
}
.close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
</style>
